import React, { Suspense, lazy, useMemo, useState } from 'react'
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  ButtonBase,
  LinearProgress,
} from '@mui/material'
import { ListCard } from '@components/index'
import { ArrowBack } from '@mui/icons-material'
import Heic2Image from '@assets/images/heic_to_jpg.png'
import ResizeImageSrc from '@assets/images/image-resize.png'

const ConvertImage = lazy(() => import('./ConvertImage'))
const EditImage = lazy(() => import('./EditImage'))
const RotateImage = lazy(() => import('./RotateImage'))

const CONVERT_FEATURE = 'convert'
const RESIZE_FEATURE = 'resize'
const ROTATE_FEATURE = 'rotate'

const ImageTools = () => {
  const [activeFeature, setActiveFeature] = useState('')
  const cards: any[] = useMemo(
    () => [
      {
        id: CONVERT_FEATURE,
        title: 'Convert Image',
        description: 'Convert HEIC to PNG or JPG',
        src: Heic2Image,
        onPress: () => setActiveFeature(CONVERT_FEATURE),
      },
      {
        id: RESIZE_FEATURE,
        title: 'Image Editor',
        description: 'Resize, crop, format and filter image',
        src: ResizeImageSrc,
        onPress: () => setActiveFeature(RESIZE_FEATURE),
      },
    ],
    [],
  )

  const onBack = () => {
    setActiveFeature('')
  }

  const renderUI = () => {
    switch (activeFeature) {
      case CONVERT_FEATURE:
        return <ConvertImage />
      case RESIZE_FEATURE:
        return <EditImage onBack={onBack} />
      case ROTATE_FEATURE:
        return <RotateImage />
      default:
        return <ListCard cards={cards as any} />
    }
  }

  return (
    <Container>
      {activeFeature && (
        <ButtonBase
          onClick={onBack}
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <ArrowBack />
          <Typography style={{ marginLeft: 12 }}>
            {`Images / ${cards?.find((_) => _.id === activeFeature)?.title}`}
          </Typography>
        </ButtonBase>
      )}
      <Suspense fallback={<LinearProgress />}>
        <Grid style={{ marginTop: 8 }}>{renderUI()}</Grid>
      </Suspense>
    </Container>
  )
}

export default ImageTools
