import React, {
  CSSProperties,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Typography, Box, Link, useMediaQuery } from '@mui/material'
import './CVPage.css'
import ImgAvatar from '@assets/images/cv-avatar.png'
import AvatarRotated from '@components/Avatar.components'
import {
  Email,
  Home,
  LinkedIn,
  Phone,
  Twitter,
  Language,
} from '@mui/icons-material'
import Rating from '@components/Rating.components'
import ExperienceItem from './ExperienceItem'

const MAIN_COLORS = ['#C17E45', '#C51B0A', '#00B1D2', '#FEC600', '#4A9E33']
const DATA = {
  name: 'Khanh DH',
  title: 'React Native | React JS Developer',
  about:
    'I have more than 9 years of software development experience (more than 6 years for React Native, 1 year for React JS, 1 year for Android and 1 year doing web/backend outsourcing projects). I have experience troubleshooting and resolving issues quickly. Able to research and apply new technology. Ability to train and manage team members. Able to communicate and read/write documents in English.',
  skills: [
    {
      groupTitle: 'Programming',
      items: [
        {
          name: 'React native',
          experience: 5,
        },
        {
          name: 'React JS',
          experience: 3,
        },
        {
          name: 'Android',
          experience: 3,
        },
        {
          name: 'Sails JS',
          experience: 3,
        },
        {
          name: 'Socket.IO',
          experience: 2,
        },
        {
          name: 'iOS',
          experience: 2,
        },
        {
          name: 'Ruby on Rails',
          experience: 2,
        },
        {
          name: 'PHP',
          experience: 2,
        },
        {
          name: '.NET',
          experience: 2,
        },
      ],
    },
    {
      groupTitle: 'Payment gateway',
      items: [
        {
          name: 'Stripe',
          experience: 3,
        },
        {
          name: 'Braintree',
          experience: 2,
        },
        {
          name: 'ZaloPay',
          experience: 2,
        },
        {
          name: 'Momo',
          experience: 2,
        },
      ],
    },
    {
      groupTitle: 'Cloud platforms',
      items: [
        {
          name: 'Firebase',
          experience: 5,
        },
        {
          name: 'Heroku',
          experience: 3,
        },
      ],
    },
    {
      groupTitle: 'Automate mobile testing',
      items: [
        {
          name: 'Maestro',
          experience: 5,
        },
        {
          name: 'Detox (Wix)',
          experience: 3,
        },
      ],
    },
    {
      groupTitle: 'CI/CD',
      items: [
        {
          name: 'Fastlane',
          experience: 4,
        },
        {
          name: 'GitHub actions',
          experience: 3,
        },
        {
          name: 'GitLab CI',
          experience: 3,
        },
      ],
    },
  ],
  //
  contact: {
    address: '',
    phone: '0939 649 769',
    email: 'khanhduong1303@gmail.com',
    linkedIn: {
      name: 'khanh-duong-98331414b',
      link: 'https://www.linkedin.com/in/khanh-duong-98331414b',
    },
    twitter: '',
    web: {
      name: 'https://tools.thientam.net/#/cv',
      link: 'https://tools.thientam.net/#/cv',
    },
  },
  experience: [
    {
      duration: '4/2018 - present',
      title: 'Blockpass',
      subtitle: 'React native | React JS developer',
      description: `Blockpass is a unique, reusable digital identity solution for any organizations that participate in the regulated and increasingly remote business environment where trust needs to be verified digitally. Blockpass offers an alternative process to cumbersome, repetitive and expensive identity document authentication, Know Your Customer (KYC) and Anti-Money Laundering (AML) screening.`,
      list: [
        `Blockpass Mobile App (React native): Allows users to enter personal information such as name, date of birth, phone number, address. As for the selfie feature, it allows facial recognition, forcing the user to perform a few actions to ensure a live selfie. For documents such as passports, ID cards or driver's licenses, use Regula SDK to scan the front and back and read the chip data on the card.`,
        `Applink (React JS): Firebase will stop providing the dynamic link feature in the future. Instead of using expensive 3rd party services, we build our own Applink. Applink will create dynamic links to open Blockpass App (iOS/Android), if the app is installed, otherwise it will open Blockpass App on the app store (iOS/Android) so users can install Blockpass App.`,
      ],
    },
    {
      duration: '3/2017 - 3/2018',
      title: 'Nexle',
      subtitle: 'React native developer',
      description:
        'Nexle is a software outsourcing company, here I had the opportunity to start learning and practicing react native.',
      list: [
        `GameDay, Courtside: GameDay and Courtside are 2 of the applications in the sport management system. GameDay app will show matches of teams, the result of matches. Courtside app allow you choose player, set player number, set score and foul, everything when the basketball game is going on.`,
        `Cancer: This is a social application to connect between the doctor and patient. The patient will create a post about their symptom and the doctor will give them the treatment.`,
      ],
    },
    {
      duration: '1/2015 - 3/2017',
      title: 'Innoria',
      subtitle: 'Web/Mobile/Backend developer',
      description:
        'Innoria is a software outsourcing company, I had the opportunity to intern and was accepted as a full-time employee for the company when I had not graduated. Here I was trained with a lot of professional knowledge, knowledge of web app/backend programming using a variety of languages ​​such as PHP, .NET, Ruby on Rails. Although software outsourcing projects are short in duration, I have the opportunity to learn and use many different languages ​​and technologies.',
      list: [
        `Gforce (Android dev): Gforce project support admin of store create task and assign to member of store,
app support online and offline mode, when switch to online mode, app will sync all task to server.`,
        `Colab (Android dev): Colab project support those who work together as a designer, photographer. They
can invite others to do the same project, user adds photo, chat, create task and complete project.`,
        `SnapArt (iOS dev): SnapArt project support user implemented steps to create the frame, users know
exactly their frame when ordered, user can preview on wall, payment by credit card, shipping.`,
        `CAS (Android dev): CAS support application crashes make requests help, the app has 2 types as normal
user and provider, normal user will require help, the provider will find nearby and to
help with maps directions.`,
        `AllerPal (Android dev): This app support shared child about allergy information, reactivity, medication,
client can create children info, they can share it with others, System help client share
information about your child for doctor or teacher, they can take care of child better.`,
      ],
    },
  ],
  education: [
    {
      duration: '2011 - 2015',
      title: 'Student',
      subtitle: 'Can Tho University',
      description: '',
      list: [],
    },
  ],
}

const CVContext = createContext<any>(undefined)
// Custom hook to use the Drawer context
export const useCVContext = () => {
  const context = useContext(CVContext)
  if (!context) {
    throw new Error('useCVContext must be used within a CVProvider')
  }
  return context
}

const Contact = ({ data, icon }: { data: any; icon: any }) => {
  if (!data) return
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {icon}
      {typeof data === 'string' ? (
        <Typography style={{ fontSize: 10, marginLeft: 6 }}>{data}</Typography>
      ) : (
        <Link
          target="_blank"
          href={data.link}
          style={{ fontSize: 10, marginLeft: 6, color: 'white' }}
        >
          {data.name}
        </Link>
      )}
    </Box>
  )
}

const Resume = () => {
  const [primaryColor, setPrimaryColor] = useState(MAIN_COLORS[0])
  const [data, setData] = useState(DATA)
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    // setTimeout(() => setPrimaryColor('red'), 3000)
  }, [])

  const renderSkills = useCallback(() => {
    return data.skills.map((gr, idx) => {
      return (
        <Box key={idx}>
          <Typography style={{ fontSize: 12, marginTop: 4 }}>
            {gr.groupTitle}
          </Typography>
          {gr.items.map((it, i) => {
            return (
              <Box
                key={`${idx}${i}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '10px',
                }}
              >
                <Typography style={{ fontSize: 10, marginLeft: 8 }}>
                  {it.name}
                </Typography>
                <Rating
                  nums={5}
                  rate={it.experience}
                  size={6}
                  color={primaryColor}
                />
              </Box>
            )
          })}
        </Box>
      )
    })
  }, [data, primaryColor])

  const renderContact = useCallback(() => {
    const icSize = 16
    return (
      <Box style={{ marginTop: 4 }}>
        <Contact
          data={data.contact.address}
          icon={<Home style={{ fontSize: icSize }} />}
        />
        <Contact
          data={data.contact.phone}
          icon={<Phone style={{ fontSize: icSize }} />}
        />
        <Contact
          data={data.contact.email}
          icon={<Email style={{ fontSize: icSize }} />}
        />
        <Contact
          data={data.contact.linkedIn}
          icon={<LinkedIn style={{ fontSize: icSize }} />}
        />
        <Contact
          data={data.contact.twitter}
          icon={<Twitter style={{ fontSize: icSize }} />}
        />
        <Contact
          data={data.contact.web}
          icon={<Language style={{ fontSize: icSize }} />}
        />
      </Box>
    )
  }, [data, primaryColor])

  const renderExperience = useCallback(() => {
    return (
      <Box>
        {data.experience.map((it, idx) => {
          return (
            <ExperienceItem
              key={idx}
              data={it}
              showLine={data.experience?.length > 1}
            />
          )
        })}
      </Box>
    )
  }, [data, primaryColor])

  const renderEducation = useCallback(() => {
    return (
      <Box>
        {data.education.map((it, idx) => {
          return (
            <ExperienceItem
              key={idx}
              data={it}
              showLine={data.education?.length > 1}
            />
          )
        })}
      </Box>
    )
  }, [data, primaryColor])

  return (
    <CVContext.Provider value={{ primaryColor }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          minHeight: '100vh',
          padding: '12px',
        }}
      >
        <Box
          className="container"
          sx={
            !isMobile
              ? {
                  boxShadow: 10,
                  borderBottom: `10px solid ${primaryColor}`,
                }
              : null
          }
        >
          {/* Left Column */}
          <Box
            style={{
              backgroundColor: '#000',
              color: '#fff',
              minWidth: 200,
            }}
          >
            <Box
              style={{
                height: '100%',
                marginRight: 4,
                marginLeft: 8,
                borderRight: `2px solid ${primaryColor}`,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '12px 0 12px 0',
                }}
              >
                <AvatarRotated
                  avatarProps={{ src: ImgAvatar }}
                  frameColor={primaryColor}
                  size={120}
                />
              </Box>

              <Typography style={{ ...styles.groupTitle, color: primaryColor }}>
                CONTACT
              </Typography>
              {renderContact()}

              <Typography style={{ ...styles.groupTitle, color: primaryColor }}>
                SKILLS
              </Typography>
              {renderSkills()}
            </Box>
          </Box>

          {/* Right Column */}
          <Box
            style={{
              backgroundColor: '#f9f9f9',
              minWidth: 600,
              padding: '24px',
            }}
          >
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              {data.name?.toUpperCase()}
            </Typography>
            <Typography variant="subtitle1">
              {data.title?.toUpperCase()}
            </Typography>
            <Typography style={{ marginTop: 14, fontSize: 12 }}>
              {data.about}
            </Typography>
            {/* Add experience, education, and skills sections here */}
            <Typography style={{ ...styles.groupTitle, color: primaryColor }}>
              EXPERIENCE
            </Typography>
            {renderExperience()}

            <Typography style={{ ...styles.groupTitle, color: primaryColor }}>
              EDUCATION
            </Typography>
            {renderEducation()}
          </Box>
        </Box>
      </Box>
    </CVContext.Provider>
  )
}

export default Resume

const styles: { [key: string]: CSSProperties } = {
  groupTitle: {
    fontSize: 14,
    marginTop: 12,
  },
}
