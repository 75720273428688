import { makeAutoObservable } from 'mobx'

interface User {}

class UserStore {
  user: User = {}

  constructor() {
    makeAutoObservable(this)
  }

  setUser(user: User) {
    this.user = user
  }

  clearUser() {
    this.user = {}
  }
}

const userStore = new UserStore()
export default userStore
