import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL, fetchFile } from '@ffmpeg/util'

interface IConvertVideoParams {
  video: File
  output: string // output.mp4
}

interface ICutVideoParams {
  video: File
  start: string
  end: string
  output: string // output.mp4
}

const CONVERT_VIDEO_SUPPORTS = [
  {
    input: '.avi',
    output: [
      {
        output: '.mp4',
        command: '-i input.avi {output}',
      },
      {
        output: '.mov',
        command: '-i input.avi {output}',
      },
    ],
  },
  {
    input: '.mp4',
    outputs: [
      {
        output: '.avi',
        command: '-i input.mp4 {output}',
      },
    ],
  },
]

class VideoUtils {
  ffmpeg: FFmpeg

  constructor() {
    this.ffmpeg = new FFmpeg()
  }

  async init() {
    try {
      const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm'
      this.ffmpeg.on('log', ({ message }) => {
        console.log('kkkkk VideoUtils ffmpeg.on log: ', message)
      })
      // toBlobURL is used to bypass CORS issue, urls with the same
      // domain can be used directly.
      await this.ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          'text/javascript',
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          'application/wasm',
        ),
        workerURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.worker.js`,
          'text/javascript',
        ),
      })
      console.log('kkkkk VideoUtils init success')
      return true
    } catch (error) {
      console.log('kkkkk VideoUtils init error: ', error)
      return false
    }
  }

  async convertVideo({ video, output }: IConvertVideoParams) {
    try {
      if (!video) return
      const fType = video.type?.split('/')?.[1]
      console.log('kkkkk convertVideo fType', fType)
      const input = video.name
      console.log('kkkkk convertVideo writeFile', input, video)
      await this.ffmpeg.writeFile(input, await fetchFile(video))
      const cmd = `-i ${input} ${output}`
      //   const cmd = `-i ${input} -c:v copy -c:a copy ${output}`
      console.log('kkkkk convertVideo cmd', cmd, cmd.split(' '))
      await this.ffmpeg.exec(cmd.split(' '))
      console.log('kkkkk convertVideo readFile', output)
      const fileData = await this.ffmpeg.readFile(output)
      console.log('kkkkk convertVideo fileData to Uint8Array')
      const data = new Uint8Array(fileData as ArrayBuffer)
      console.log('kkkkk convertVideo URL.createObjectURL')
      const _url = URL.createObjectURL(
        new Blob([data.buffer], { type: `video/${fType}` }),
      )
      console.log('kkkkk convertVideo _url', _url)
      return _url
    } catch (error) {
      console.log('kkkkk convertVideo error', error)
    }
  }

  async cutVideo({ video, start, end, output }: ICutVideoParams) {
    try {
      if (!video) return ''
      const fType = video.type?.split('/')?.[1]
      console.log('kkkkk cutVideo fType', fType)
      const input = 'input.mp4' //video.name
      console.log('kkkkk cutVideo input', input, video)
      await this.ffmpeg.writeFile(input, await fetchFile(video))
      const cmd = `-i ${input} -ss ${start} -t ${end} -c copy ${output}`
      console.log('kkkkk cutVideo cmd', cmd, cmd.split(' '))
      await this.ffmpeg.exec(cmd.split(' '))
      const fileData = await this.ffmpeg.readFile(output)
      const data = new Uint8Array(fileData as ArrayBuffer)
      const _url = URL.createObjectURL(
        new Blob([data.buffer], { type: `video/${fType}` }),
      )
      console.log('kkkkk convertVideo _url', _url)
      return _url
    } catch (error) {
      return ''
    }
  }
}

export default new VideoUtils()
