import { makeAutoObservable } from 'mobx'

class SettingStore {
  activeMenu = 'home' // Initialize activeMenu

  constructor() {
    makeAutoObservable(this)
  }

  setActiveMenu(menuId: string) {
    this.activeMenu = menuId
  }
}

const settingStore = new SettingStore()
export default settingStore
