import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export const downloadFiles = async (
  data: File | string | File[],
  zipName = 'utility-toolkit-zip-files.zip',
  fileName = 'utility-toolkit-download',
) => {
  if (Array.isArray(data)) {
    const zip = new JSZip()
    data.forEach((file) => {
      zip.file(file.name, file)
    })

    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, zipName)
  } else {
    saveAs(data, fileName)
  }
}
