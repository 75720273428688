// App.js
import { Avatar } from '@mui/material'
import { adjustColor } from '@utils/Utils'
import React, { CSSProperties } from 'react'

interface IProps {
  containerStyle?: any
  size?: number
  color?: string
  nums?: number
  rate?: number
}

const Rating = ({
  size = 12,
  color = 'red',
  containerStyle = {},
  nums = 5,
  rate = 5,
}: IProps) => {
  const styles: { [key: string]: CSSProperties } = {
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    rate: {
      width: size,
      height: size,
      backgroundColor: color,
      marginLeft: 6,
      transform: 'rotate(45deg)',
    },
  }

  return (
    <div style={{ ...styles.container, ...containerStyle }}>
      {Array(nums)
        .fill(1)
        .map((it, idx) => {
          return (
            <div
              key={idx}
              style={{
                ...styles.rate,
                backgroundColor: color,
                opacity: idx < rate ? 1 : 0.5,
              }}
            />
          )
        })}
    </div>
  )
}

export default Rating
