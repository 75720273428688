// App.js
import { Avatar } from '@mui/material'
import React, { CSSProperties } from 'react'

interface IProps {
  containerStyle?: any
  avatarProps?: any
  size?: number
  backgroundColor?: string
  frameColor?: string
}

const AvatarRotated = ({
  avatarProps = {},
  size = 150,
  backgroundColor = '#000',
  frameColor = 'red',
  containerStyle = {},
}: IProps) => {
  const AVATAR_SIZE = size
  const styles: { [key: string]: CSSProperties } = {
    container: {
      position: 'relative',
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      overflow: 'hidden',
    },
    square: {
      position: 'absolute',
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    rhombus: {
      position: 'absolute',
      top: -AVATAR_SIZE / 2,
      left: -AVATAR_SIZE / 2,
      width: AVATAR_SIZE * 2,
      height: AVATAR_SIZE * 2,
      transform: 'rotate(45deg)',
      border: `${AVATAR_SIZE * 0.65}px solid ${backgroundColor}`,
    },
    frame: {
      position: 'absolute',
      top: AVATAR_SIZE * 0.15,
      left: AVATAR_SIZE * 0.15,
      width: AVATAR_SIZE * 0.7,
      height: AVATAR_SIZE * 0.7,
      transform: 'rotate(45deg)',
      border: `3px solid ${frameColor}`,
    },
  }

  return (
    <div style={{ ...styles.container, ...containerStyle }}>
      <div style={styles.square}>
        <Avatar
          {...avatarProps}
          style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
        />
      </div>
      <div style={styles.rhombus}></div>
      <div style={styles.frame}></div>
    </div>
  )
}

export default AvatarRotated
