import React from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Container,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface ICard {
  title: string
  description: string
  img?: string
  src?: string
  link?: string
  onPress?: (...args: any) => void
}
interface IProps {
  cards: [ICard]
  cols?: number
}

const ListCard = ({ cards, cols = 2 }: IProps) => {
  const navigate = useNavigate()

  return (
    <Container>
      <Grid
        container
        spacing={2}
        sx={{
          '@media (min-width: 500px)': {
            gridTemplateColumns: `repeat(${cols}, 1fr)`,
            display: 'grid',
          },
        }}
      >
        {cards.map((card, index) => (
          <Grid
            item
            key={index}
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Card
              sx={{
                width: '100%',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 3,
                },
              }}
              raised
              onClick={() => {
                card.link && navigate(card.link)
                card.onPress?.()
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={card.img}
                src={card.src}
                alt={card.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default ListCard
