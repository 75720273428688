export const adjustColor = (hex: string, percentage: number) => {
  // Convert hex to RGB
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  // Adjust brightness
  const adjust = (color: number, percentage: number) =>
    Math.max(0, Math.min(255, Math.round(color * percentage)))
  const newR = adjust(r, percentage)
  const newG = adjust(g, percentage)
  const newB = adjust(b, percentage)

  // Convert RGB back to hex
  const toHex = (num: number) => num.toString(16).padStart(2, '0')
  return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`
}

export const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  const pad = (num: number) => String(num).padStart(2, '0')

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
}
