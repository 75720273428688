import React, { Suspense, lazy, useMemo, useState } from 'react'
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  ButtonBase,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material'
import { ListCard } from '@components/index'
import { ArrowBack } from '@mui/icons-material'
import ImgVideoConverter from '@assets/images/video-converter.png'
import ImgTrimVideo from '@assets/images/trim-video.png'
import TrimVideo from './TrimVideo'

const ConvertVideo = lazy(() => import('./ConvertVideo'))

const CONVERT_FEATURE = 'convert'
const TRIM_FEATURE = 'trim'

const ImageTools = () => {
  const [activeFeature, setActiveFeature] = useState('')
  const [isAlert, setIsAlert] = useState(false)

  const cards: any[] = useMemo(
    () => [
      {
        id: CONVERT_FEATURE,
        title: 'Convert Video',
        description: 'Convert video to other format',
        src: ImgVideoConverter,
        // onPress: () => setActiveFeature(CONVERT_FEATURE),
        onPress: () => setIsAlert(true),
      },
      {
        id: TRIM_FEATURE,
        title: 'Trim Video',
        description: 'Trim video',
        src: ImgTrimVideo,
        onPress: () => setActiveFeature(TRIM_FEATURE),
      },
    ],
    [],
  )

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setIsAlert(false)
  }

  const onBack = () => {
    setActiveFeature('')
  }

  const renderUI = () => {
    switch (activeFeature) {
      case CONVERT_FEATURE:
        return <ConvertVideo />
      case TRIM_FEATURE:
        return <TrimVideo />
      default:
        return <ListCard cards={cards as any} />
    }
  }

  return (
    <Container>
      {activeFeature && (
        <ButtonBase
          onClick={onBack}
          style={{
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <ArrowBack />
          <Typography style={{ marginLeft: 12 }}>
            {`Videos / ${cards?.find((_) => _.id === activeFeature)?.title}`}
          </Typography>
        </ButtonBase>
      )}
      <Suspense fallback={<LinearProgress />}>
        <Grid style={{ marginTop: 8 }}>{renderUI()}</Grid>
      </Suspense>
      <Snackbar
        open={isAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleAlertClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Coming soon
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default ImageTools
