// App.js
import { Avatar, Box, Typography } from '@mui/material'
import { adjustColor } from '@utils/Utils'
import React, { CSSProperties } from 'react'
import { useCVContext } from './CVPage'

interface IProps {
  containerStyle?: any
  data: {
    duration: string
    title: string
    subtitle: string
    description: string
    list: string[]
  }
  showLine: boolean
}

const ExperienceItem = ({
  data,
  containerStyle = {},
  showLine = true,
}: IProps) => {
  const { primaryColor } = useCVContext()
  return (
    <div style={{ ...styles.container, ...containerStyle }}>
      <Box style={{ ...styles.leftCol }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 5,
          }}
        >
          <Dot />
          {showLine && (
            <div
              style={{
                width: 2,
                height: '100%',
                backgroundColor: primaryColor,
                margin: '4px',
              }}
            />
          )}
        </div>

        <Text style={{ marginLeft: 4 }}>{data.duration}</Text>
      </Box>
      <Box style={{ ...styles.rightCol }}>
        <Text style={styles.title}>{data.title}</Text>
        <Text style={{ color: primaryColor }}>{data.subtitle}</Text>
        {data.description && <Text>{data.description}</Text>}
        {data.list?.map((it, idx) => (
          <ListItem key={idx} value={it} />
        ))}
      </Box>
    </div>
  )
}

export default ExperienceItem

const Text = ({ children, style, ...props }: any) => {
  return (
    <Typography {...props} style={{ fontSize: 12, ...style }}>
      {children}
    </Typography>
  )
}

const ListItem = ({ value }: any) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Dot style={{ marginLeft: 8, marginTop: 4 }} />
      <Text
        style={{
          flex: 1,
          fontSize: 11,
          marginLeft: 8,
        }}
      >
        {value}
      </Text>
    </div>
  )
}

const Dot = ({ style = {} }: any) => {
  const { primaryColor } = useCVContext()
  return (
    <div
      style={{
        width: 6,
        height: 6,
        backgroundColor: primaryColor,
        transform: 'rotate(45deg)',

        ...style,
      }}
    />
  )
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'row',
  },
  rightCol: {
    flex: 1,
    marginLeft: 12,
  },
  title: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  subTitle: {},
}
