import React, { useCallback, useEffect } from 'react'
import { CssBaseline } from '@mui/material'
import { Drawer } from '@components/index'
import {
  AppPage,
  HomePage,
  ImageToolsPage,
  VideoToolsPage,
  CVPage,
} from '@pages/index'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter,
} from 'react-router-dom'

const App = () => {
  const renderPage = useCallback((page: React.ReactElement) => {
    return (
      <Drawer>
        <AppPage>{page}</AppPage>
      </Drawer>
    )
  }, [])

  return (
    <div className="App">
      <CssBaseline />

      <HashRouter>
        <Routes>
          <Route path="/" element={renderPage(<HomePage />)} />
          <Route path="/images" element={renderPage(<ImageToolsPage />)} />
          <Route path="/videos" element={renderPage(<VideoToolsPage />)} />
          <Route path="/cv" element={<CVPage />} />
        </Routes>
      </HashRouter>
    </div>
  )
}

export default App
