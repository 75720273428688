import React, { useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { Box, Typography } from '@mui/material'

interface IProps {
  onChange?: (...args: any) => void
  fileType?: Accept | undefined
  description?: string
  multiple?: boolean
}

const UploadZone = ({
  onChange,
  fileType = undefined,
  description = 'Drag and drop some files here, or click to select files',
  multiple = true,
}: IProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    // Handle file upload
    console.log(acceptedFiles)
    onChange?.(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileType,
    multiple,
  })

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed #ddd',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
        cursor: 'pointer',
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <input {...getInputProps()} />
      <Typography>{description}</Typography>
      {/* {isDragActive ? (
        <Typography>Drop the files here...</Typography>
      ) : (
        <Typography>
          Drag and drop some files here, or click to select files
        </Typography>
      )} */}
    </Box>
  )
}

export default UploadZone
