import React from 'react'
import {
  ImageList as MUIImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  IconButton,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadFiles } from '@utils/FileUtils'

interface IProps {
  files: File[]
}

const ImageList = ({ files }: IProps) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const theme = useTheme()

  return (
    <MUIImageList cols={isMobile ? 2 : 3}>
      {files.map((file, index) => (
        <ImageListItem key={index}>
          <img
            src={URL.createObjectURL(file)}
            alt={`uploaded-file-${index}`}
            loading="lazy"
          />
          <ImageListItemBar
            title={<Typography fontSize={14}>{file.name}</Typography>}
            subtitle={<Typography fontSize={10}>{file.type}</Typography>}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={file.name}
                onClick={() => downloadFiles(file)}
              >
                <DownloadIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </MUIImageList>
  )
}

export default ImageList
