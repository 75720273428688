import { useStore } from '@stores/Stores'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MENU } from '@utils/Constants'

const AppPage = ({ children }: any) => {
  const location = useLocation()
  const { settingStore } = useStore()

  useEffect(() => {
    const path = location.pathname
    switch (path) {
      case '/':
        settingStore.setActiveMenu(MENU.HOME)
        break
      case '/images':
        settingStore.setActiveMenu(MENU.IMAGES)
        break
      case '/videos':
        settingStore.setActiveMenu(MENU.VIDEOS)
        break
    }
  }, [location])
  return <>{children}</>
}

export default AppPage
