import React, { createContext, useCallback, useContext, useMemo } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Photo from '@mui/icons-material/Photo'
import HomeOutlined from '@mui/icons-material/HomeOutlined'
import Home from '@mui/icons-material/Home'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {
  PhotoOutlined,
  VideoFile,
  VideoFileOutlined,
} from '@mui/icons-material'
import { useStore } from '@stores/Stores'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { MENU } from '@utils/Constants'

const drawerWidth = 240

// Define the context type
interface DrawerContextType {
  handleDrawerToggle: () => void
}

// Create the context
const DrawerContext = createContext<DrawerContextType | undefined>(undefined)

// Custom hook to use the Drawer context
export const useDrawerContext = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider')
  }
  return context
}

const ResponsiveDrawer = observer(
  (props: {
    children?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >
  }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const [isClosing, setIsClosing] = React.useState(false)
    const { settingStore } = useStore()
    const navigate = useNavigate()

    const listItems = useMemo(
      () => [
        {
          id: MENU.HOME,
          title: 'Home',
          inActiveIcon: <HomeOutlined />,
          activeIcon: <Home />,
          action: () => {
            settingStore.setActiveMenu(MENU.HOME)
            navigate('/')
          },
        },
        {
          id: MENU.IMAGES,
          title: 'Image Tools',
          inActiveIcon: <PhotoOutlined />,
          activeIcon: <Photo />,
          action: () => {
            settingStore.setActiveMenu(MENU.IMAGES)
            navigate('/images')
          },
        },
        {
          id: MENU.VIDEOS,
          title: 'Video Tools',
          inActiveIcon: <VideoFileOutlined />,
          activeIcon: <VideoFile />,
          action: () => {
            settingStore.setActiveMenu(MENU.VIDEOS)
            navigate('/videos')
          },
        },
      ],
      [],
    )

    const activeTitle = useMemo(
      () => listItems.find((_) => _.id === settingStore.activeMenu)?.title,
      [settingStore.activeMenu],
    )

    const handleDrawerClose = () => {
      setIsClosing(true)
      setMobileOpen(false)
    }

    const handleDrawerTransitionEnd = () => {
      setIsClosing(false)
    }

    const handleDrawerToggle = () => {
      if (!isClosing) {
        setMobileOpen(!mobileOpen)
      }
    }

    const drawer = (
      <div>
        <Toolbar>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <IconButton>
            <MenuIcon />
          </IconButton> */}
            <Typography variant="h6" style={{ marginLeft: '16px' }}>
              {'Utility toolkit'}
            </Typography>
          </div>
        </Toolbar>

        <Divider />
        <List>
          {listItems.map((it, idx) => (
            <ListItem key={it.id} disablePadding>
              <ListItemButton
                selected={settingStore.activeMenu === it.id}
                onClick={() => {
                  handleDrawerToggle()
                  it.action?.()
                }}
              >
                <ListItemIcon>
                  {settingStore.activeMenu === it.id
                    ? it.activeIcon
                    : it.inActiveIcon}
                </ListItemIcon>
                <ListItemText primary={it.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider /> */}
        {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      </div>
    )

    return (
      <DrawerContext.Provider value={{ handleDrawerToggle }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <AppBar
            position="relative"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {activeTitle}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              aria-label="mailbox folders"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Drawer
                // container={container}
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </Drawer>
            </Box>
            <Box
              component="main"
              sx={{
                flex: 1,
                width: '100%',
                height: '100%',
                padding: '10px',
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
      </DrawerContext.Provider>
    )
  },
)

export default ResponsiveDrawer
