import { useState, useRef, useEffect, useCallback } from 'react'
import { Stepper, UploadZone } from '@components/index'
import { Box, Button, Container, Typography } from '@mui/material'
import VideoUtils from '@utils/VideoUtils'
import { downloadFiles } from '@utils/FileUtils'
import { formatTime } from '@utils/Utils'

const STEPS = ['Upload', 'Processing', 'Finished']

function TrimVideo() {
  const [loaded, setLoaded] = useState(false)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [url, setUrl] = useState<string | null>(null)
  const [step, setStep] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const success = await VideoUtils.init()
    success && setLoaded(true)
  }

  const onTrimVideo = async () => {
    if (!selectedFile) return
    const videoURL = await VideoUtils.cutVideo({
      video: selectedFile,
      start: formatTime(startTime),
      end: formatTime(endTime - startTime),
      output: selectedFile.name,
    })
    if (videoURL) {
      setUrl(videoURL)
      setStep(2)
      console.log('kkkkk onTrimVideo videoURL', videoURL)
    }
  }

  const onFileChange = (data: File[]) => {
    if (data.length > 0) {
      const _file = data[0]
      console.log('kkkkk onFileChange setStep = 1', _file)
      setSelectedFile(_file)
      setStep(1)
      setUrl(URL.createObjectURL(_file))
      console.log('kkkkk onFileChange _url ', URL.createObjectURL(_file))
    }
  }

  const onDownload = () => {
    url && downloadFiles(url, undefined, 'utility-toolkit-trim-video')
  }

  const onTimeUpdate = (e: any) => {
    const _currentTime = e.target.currentTime
    console.log('kkkkk onTimeUpdate', _currentTime)
    setCurrentTime(_currentTime)
    if (_currentTime < startTime) {
      setStartTime(0)
      setEndTime(0)
    }
  }

  const onStartTime = () => {
    setEndTime(0)
    setStartTime(currentTime)
    videoRef.current?.pause()
  }

  const onEndTime = () => {
    setEndTime(currentTime)
    videoRef.current?.pause()
  }

  const renderUI = () => {
    switch (step) {
      case 0:
        return (
          <UploadZone
            onChange={onFileChange}
            fileType={{
              'video/mp4': [],
              'video/webm': [],
            }}
            description="Drag and drop video (.mp4, .webm) here, or click to select video"
          />
        )
      case 1:
        return (
          selectedFile && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography>Current time: {formatTime(currentTime)}</Typography>
                <Typography style={{ marginLeft: 16 }}>
                  Start time: {startTime ? formatTime(startTime) : 'None'}
                </Typography>
                <Typography style={{ marginLeft: 16 }}>
                  End time: {endTime ? formatTime(endTime) : 'None'}
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Button
                  variant="contained"
                  style={{ marginLeft: 12 }}
                  size="small"
                  onClick={onStartTime}
                >
                  Set current is start time
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 12 }}
                  size="small"
                  onClick={onEndTime}
                  disabled={
                    !startTime ||
                    Math.floor(currentTime) <= Math.floor(startTime)
                  }
                >
                  Set current is end time
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 12 }}
                  size="small"
                  onClick={onTrimVideo}
                  disabled={!startTime || !endTime}
                >
                  Trim
                </Button>
              </Box>
            </Box>
          )
        )
      case 2:
        return (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" onClick={onDownload}>
                Download
              </Button>
            </Box>
          </>
        )

      default:
        break
    }
  }

  return (
    <Container>
      <Stepper steps={STEPS} activeStep={step} />
      <Box my={2}>
        {renderUI()}
        <Box sx={{ height: 500, marginTop: '8px' }}>
          {url && (
            <video
              ref={videoRef}
              controls
              src={url}
              height="100%"
              width="100%"
              onTimeUpdate={onTimeUpdate}
            />
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default TrimVideo
