import React, { createContext, useContext, ReactNode } from 'react'
import settingStore from './SettingStore'
import userStore from './UserStore'

export default {
  userStore,
  settingStore,
}

export const StoreContext = createContext({
  settingStore,
  userStore,
})

interface StoreProviderProps {
  children: ReactNode
}
export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => (
  <StoreContext.Provider value={{ settingStore, userStore }}>
    {children}
  </StoreContext.Provider>
)

export const useStore = () => useContext(StoreContext)
