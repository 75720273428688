import { useMemo } from 'react'
import ListCard from '@components/ListCard.components'
import ImageEditor from '@assets/images/image-editor.png'
import VideoEditor from '@assets/images/video-editor.png'

const HomePage = () => {
  const cards: any = useMemo(
    () => [
      {
        title: 'Image Tools',
        description:
          'Convert .heic to png/jpg, resize image, rotate image, merge image, crop image',
        src: ImageEditor,
        link: '/images',
      },
      {
        title: 'Video Tools',
        description: 'Cut video, resize video, add effects',
        src: VideoEditor,
        link: '/videos',
      },
    ],
    [],
  )

  return <ListCard cards={cards} />
}

export default HomePage
